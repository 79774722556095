@import "../../../../variables";

.card {
  width: 100%;
  background-color: $bg-neutral-white;
  border-radius: 2px;
}

.bottomPaddingNone {
  padding: 8px 24px 0;
}

.bottomPaddingDefault {
  padding: 8px 24px;
}
