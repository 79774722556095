@import "../../../variables";

.headInner {
  display: grid;
  gap: 16px;
  padding: 0 24px;
}

.bodyInner {
  display: grid;
  gap: 16px;
}

.catImage {
  position: absolute;
  bottom: 16px;
  left: 16px;
}

.title {
  @include content-4xl-bold;
  padding: 8px 0;
  color: $content-neutral-dark;
  text-align: center;
}

.note {
  li {
    position: relative;
    padding-left: 1.6rem;
    color: $content-neutral-dark;
    list-style: none;
    @include content-large-regular;

    &::before {
      position: absolute;
      top: 2px;
      left: 2px;
      font-size: 1.25rem;
      content: "・";
    }
  }
}

.importantText {
  @include content-large-bold;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding: 16px 0;
}
