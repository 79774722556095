@import "../../../../variables";

.dialog {
  position: relative;
  display: grid;
  gap: 10px;
  width: 523px;
  padding: 16px;
  background-color: $bg-neutral-lighter;
  border-radius: 8px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2),
    0px 26px 80px 0px rgba(0, 0, 0, 0.2);
}
