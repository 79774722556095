@import "../../../variables";

.inner {
  display: flex;
  gap: 8px;
  align-items: center;
}

.caution {
  @include content-large-bold;
  color: $content-neutral-dark;
}

.textBox {
  padding-bottom: 30px;
}

.list {
  & li {
    list-style: none;
  }
}

.textFirst {
  @include content-large-bold;
  position: relative;
  padding-left: 1.5em;
  color: $content-neutral-dark;

  &::before {
    position: absolute;
    left: 0.2em;
  }

  &:first-of-type::before {
    content: "1.";
  }

  &:nth-of-type(2)::before {
    content: "2.";
  }
}

.textSecond {
  @include content-large-regular;
  position: relative;
  padding-left: 3em;
  color: $content-neutral-light;

  &::before {
    position: absolute;
    left: 1.8em;
  }

  &:first-of-type::before {
    content: "a.";
  }

  &:nth-of-type(2)::before {
    content: "b.";
  }
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 8px;
}
