@import "../../../variables";

.bodyInner {
  display: grid;
  gap: 16px;
}

.title {
  @include content-4xl-bold;
  padding: 8px 0;
  color: $content-neutral-dark;
  text-align: center;
  letter-spacing: 1.2px;
}

.note {
  @include content-large-regular;
  color: $content-neutral-dark;
  letter-spacing: 0.8px;
}

.noteTop {
  text-align-last: justify;
}

.noteBold {
  @include content-large-bold;
}

.buttonContainer {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 16px 0;
}

.catImage {
  position: absolute;
  top: 0;
  left: 29px;
}
