@import "../../../variables";

.inner {
  display: grid;
  gap: 16px;
  padding: 0 24px;
}

.title {
  @include content-4xl-bold;
  padding: 8px 0;
  text-align: center;
}

.description {
  @include content-xl-large;
}

.note {
  @include content-large-regular;

  & > span {
    @include content-large-bold;
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding: 16px 0;
}
